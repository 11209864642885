
import { defineComponent } from "vue";
import { NCarousel } from "naive-ui";

export default defineComponent({
  name: "CarouselTitle",
  components: {
    NCarousel,
  },
});
