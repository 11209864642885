
import { NGradientText } from "naive-ui";
import { defineComponent, PropType, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "NavHeader",
  components: {
    NGradientText,
  },
  emits: ["close"],
  props: {
    showMobileNav: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const primaryColor = "#2e426e";
    const secondaryColor = "#86f0f7";
    const route = useRoute();

    return {
      primaryColor,
      secondaryColor,
      ref,
      route,
      emit
    };
  },
});
