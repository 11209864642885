
import { defineComponent } from "vue";
import instagramIcon from "@/assets/icons/instagram.svg";
import facebookIcon from "@/assets/icons/facebook.svg";
import youtubeIcon from "@/assets/icons/youtube.svg";

export default defineComponent({
  name: "NavFooter",
  setup() {
    const links: { name: string; url: string; img: string; }[] = [{
      name: "Instagram",
      url: "https://www.instagram.com/argot_music/",
      img: instagramIcon
    }, {
      name: "Facebook",
      url: "https://www.facebook.com/bandargot",
      img: facebookIcon
    }, {
      name: "Youtube",
      url: "https://www.youtube.com/@argotmusic5288/videos",
      img: youtubeIcon,
    }];

    return {
      links
    };
  }
});
