import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-faacf99a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "link-wrapper",
        key: link.url
      }, [
        _createElementVNode("a", {
          href: link.url,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: link.img,
            alt: link.name
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1)
      ]))
    }), 128))
  ]))
}