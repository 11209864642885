
import { defineComponent } from "vue";
import CarouselTitleVue from "@/components/CarouselTitle.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    CarouselTitleVue,
  }
});
