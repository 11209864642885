
import "vfonts/Lato.css";
import "vfonts/FiraCode.css";
import { defineComponent, ref } from "vue";
import NavHeader from "@/components/NavHeader.vue";
import NavFooter from "@/components/NavFooter.vue";

export default defineComponent({
  name: "app",
  components: {
    NavHeader,
    NavFooter
  },
  setup() {
    const showMobileNav = ref<boolean>(false);

    const toggleMobileNav = () => {
      showMobileNav.value = !showMobileNav.value;
    };

    return {
      showMobileNav,
      toggleMobileNav,
    };
  },
});
