import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51ffac9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_carousel_title_vue = _resolveComponent("carousel-title-vue")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_carousel_title_vue)
  ]))
}